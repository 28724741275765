.address_control_mock {
  width: 100%;
  height: 274px;
  display: flex;
}

.address_control_bg {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  inset: 0;
  z-index: 99;
  background: #fff;
}

.address_control_wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 999999999;

  &.active {
    inset: 0;
    overflow: hidden;
    height: 100%;
    .address_control {
      margin-top: auto;
    }
  }

  &.max_height {
    position: fixed;
    inset: 0;
    width: 100%;
    z-index: 99999999999;
    overflow: hidden;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    overflow: hidden;
  }
}

.address_control {
  width: 100%;
  background: #fff;
  padding: 10px 15px 15px;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  height: auto;
  overflow: auto;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 99999999999;

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 100%;
    .bar {
      width: 50px;
      height: 5px;

      background: #d9d9d9;
      border-radius: 10px;
    }

    .input_with_button {
      display: flex;
      width: 100%;
      gap: 10px;
      align-items: flex-start;
      justify-content: center;

      .cancel_button {
        height: 48px;
        min-width: 80px;
        background: transparent;
        border: none;
        color: #000;
      }
    }

    .input_wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 3px;

      .select_with_search {
        // position: absolute;
        // z-index: 9;
        // top: 25px;
        // width: calc(100% - 30px);
        // height: 48px;
      }

      .input {
        position: relative;
        z-index: 9;
      }

      .message {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
      }
    }

    .submit_button {
      // margin-top: 30px;
    }

    .address_details {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .address_details_inputs {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .input {
        width: 100%;
      }
    }
  }

  &.active {
    transition: all 0.3s ease-in-out;
    position: fixed;
    inset: 0;
    overflow: hidden;
    height: 100%;

    .header {
      .input_with_button {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 10px 15px;
      }
    }
  }
}

.address_list_input_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  overflow: hidden;
  position: fixed;
  inset: 0;
  z-index: 9999999999;
  background: #fff;
  padding: 20px 15px 15px;

  .input_search_wrapper {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    top: 10px;
    left: 0;
    right: 0;
    z-index: 99999999999;
    padding: 0 15px;

    .cancel_button {
      height: 48px;
      min-width: 80px;
      background: transparent;
      border: none;
      color: #000;
    }
  }

  .address_list {
    padding: 0 10px;
    width: 100%;
    flex-direction: column;
    display: flex;
    padding-top: 30px;

    .address_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 15px 0px;
      width: 100%;
      border-bottom: 1px solid #e0e0e0;

      .address {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }

      .description {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #8b8b8b;
      }
    }
  }
}
