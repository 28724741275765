@import "shared/styles/typography.scss";

.b {
  ul {
    list-style: none;
  }
}

.title {
  @extend %header_4;
  font-size: 24px;
}
