@import "shared/styles/media.scss";
@import "shared/styles/typography.scss";

.navigation {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 99999;
  width: 100%;

  @include lg {
    margin-bottom: 52px;
  }
  .info_nav {
    padding: 10px var(--container-horizontal-padding);
    background: var(--background);

    .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 30px;

      @media (max-width: 1080px) {
        gap: 20px;
      }
    }

    @include lg {
      position: absolute;
      z-index: -1;
      max-width: 100%;
      overflow: hidden;
    }

    .left_items_list {
      display: flex;
      align-items: center;
      gap: 30px;
      padding: 0;
      margin: 0;

      @media (max-width: 1080px) {
        gap: 20px;
      }

      .icon {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
      }

      .item {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.28px;

        @extend %paragraph_16_med;

        a {
          display: flex;
          gap: 4px;
          align-items: center;
        }
      }
    }

    .right_items_list {
      display: flex;
      align-items: center;
      gap: 23px;
      padding: 0;
      margin: 0;

      @media (max-width: 1080px) {
        gap: 20px;
      }

      .logo {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        object-fit: contain;

        @include xl {
          width: 20px;
          height: 20px;
        }
      }

      .item {
        display: flex;
        align-items: center;

        @extend %paragraph_14_med;
        a {
          display: flex;
          gap: 4px;
          align-items: center;
        }
      }
    }
  }

  .main_nav {
    color: var(--white);
    background: var(--text-color);
    padding: 29px var(--container-horizontal-padding);

    @include lg {
      padding: 12px var(--container-horizontal-padding);
    }

    .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .left_side {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 51px;

      .poizon_logo {
        display: flex;
        align-items: center;
      }
      @media (max-width: 1185px) {
        gap: 30px;
      }

      @include sm {
        gap: 15px;
      }
    }

    .logo {
      width: 135px;
      height: auto;
    }

    .left_items_list {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px;
      padding: 0;
      margin: 0;

      @media (max-width: 1185px) {
        gap: 20px;
      }

      @include lg {
        display: none;
      }

      .item {
        display: flex;

        @include xl {
          .menu_button {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 19.6px */
            letter-spacing: -0.28px;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }

        @media (max-width: 1080px) {
          .menu_button {
            img {
              display: none;
            }
          }
        }
      }
    }

    .right_side {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px;

      @media (max-width: 1185px) {
        gap: 20px;
      }

      @include lg {
      }

      .icons_list {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        .icons_item {
          display: none;

          width: 30px;
          height: 30px;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          span {
            display: none;
          }

          @include lg {
            display: block;
            img {
              width: 30px;
              height: 30px;
            }
          }

          @include sm {
            width: 25px;
            height: 25px;

            img {
              height: 25px;
              width: 25px;
            }
          }
        }
      }
    }

    .right_items_list {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px;
      padding: 0;
      margin: 0;

      @media (max-width: 1185px) {
        gap: 20px;
      }

      @include lg {
        display: none;
      }
      .item {
        display: flex;

        @extend %paragraph_16_med;
        a {
          color: var(--white);

          @include xl {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 19.6px */
            letter-spacing: -0.28px;
          }
          &:hover {
            color: var(--accent);
          }
        }
      }
    }
  }

  .products_nav {
    display: none;
    height: auto;
    width: 100vw;
    position: absolute;
    top: 100%;

    @include lg {
      display: flex;
    }

    &::after {
      content: "";
      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 52px;
      z-index: -1;
      background: var(--background);
    }

    .products_container {
      display: flex;
      max-width: 100vw;
      width: 100%;

      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
      flex-direction: row;
      align-items: flex-start;
      gap: 30px;
      padding: 15px var(--container-horizontal-padding);
      position: relative;

      .item {
        width: auto;
        flex-shrink: 0;
      }

      .menu {
        display: flex;
        flex-direction: column;
      }

      .menu_list {
        position: relative;
        top: 100%;
        margin-top: 10px;
      }
    }
  }
}
