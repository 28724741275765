@import "shared/styles/media";
@import "shared/styles/typography.scss";

.products_page {
  padding: 0 var(--container-horizontal-padding);

  @include lg {
    --container-horizontal-padding: 20px;
  }

  @include sm {
    --container-horizontal-padding: 16px;
  }

  .body {
    display: flex;
    flex-direction: row;
    gap: 80px;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;

    @include xl {
      gap: 25px;
    }

    @include lg {
      gap: 25px;
    }

    @include md {
      flex-direction: column;
      margin: 20px 0;
    }

    .products {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      .products_heading {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @include sm {
          flex-direction: column;
          align-items: flex-start;
        }

        .total {
          display: inline;
          flex-shrink: 0;

          .label {
            @extend %paragraph_medium;
          }

          @extend %paragraph_regular;
        }
      }
    }
  }
}

.subcategories {
  margin-top: 40px;

  @include sm {
    margin-top: 20px;
  }
}
