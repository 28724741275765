.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  &.default {
    gap: 10px;
    svg {
      width: 17px;
      height: 17px;
      color: var(--medium-gray);
      flex-shrink: 0;
    }

    .breadcrumb {
      color: var(--medium-gray);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 16.8px */
      letter-spacing: -0.24px;
    }
  }

  &.secondary {
    gap: 5px;
    color: var(--ui-main-black);
    font-family: var(--font);

    svg {
      width: 10px;
      height: 10px;
      flex-shrink: 0;
    }

    .breadcrumb {
      display: flex;
      padding: 3px 7px;
      flex-direction: row;
      align-items: center;
      gap: 3px;

      border-radius: 5px;
      background: var(--ui-main-light-gray, #f2f2f2);

      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
    }
  }
}
