@import "shared/styles/typography.scss";

.radio {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;

  &:hover {
    .check:not(.disabled, .checked) {
      border-color: var(--black);
    }
  }
}

.disabled {
}

.box {
  padding: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.text {
  @extend %paragraph_16_med;
  text-wrap: wrap;
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
}

.check {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid var(--medium-gray);
  padding: 2px;
  border-radius: 50%;
  flex-shrink: 0;

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .icon {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0;
    color: transparent;
  }

  &:not(.disabled):hover {
    border-color: var(--black);
  }

  &.checked .icon {
    background: var(--accent-dark);
  }

  .disabled.checked .icon {
    color: var(--medium-gray);
  }
}
