@import "./media";

// variables for fonts
// --font-roboto-condensed: Roboto Condensed
// --font-open-sans: Open Sans
// --font-open-sans-condensed: Open Sans Condensed

/* big-header-txt */
%big_header_txt {
  font-family: var(--font-roboto-condensed), var(--font-open-sans), sans-serif;
  font-size: 60px;
  font-style: italic;
  font-weight: 700; /* must be 500 */
  line-height: 120%; /* 72px */
  letter-spacing: -1.8px;

  @include sm {
    /* big-header-txt @m */
    font-size: 40px;
    font-style: italic;
    font-weight: 500;
    line-height: 120%; /* 48px */
    letter-spacing: -1.2px;
  }
}

/* big-txt */
%big_txt {
  font-family: var(--font-roboto-condensed), var(--font-open-sans), sans-serif;
  font-size: 32px;
  font-style: italic;
  font-weight: 300;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.96px;
}

/* header-1-txt */
%header_1_txt {
  font-family: var(--font-open-sans-condensed), var(--font-open-sans), sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 60px */
  letter-spacing: -1.5px;
  text-transform: uppercase;

  @include sm {
    /* header-1-@m-txt */
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 48px */
    letter-spacing: -1.2px;
    text-transform: uppercase;
  }
}

/* header-2-txt */
%header_2_txt {
  font-family: var(--font-roboto-condensed), var(--font-open-sans), sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 42px */
  letter-spacing: -0.6px;
}

/* header-3-txt */
%header_3_txt {
  font-family: var(--font-roboto-condensed), var(--font-open-sans), sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 30.8px */
  letter-spacing: -0.44px;
}

/* header-4-txt */
%header_4_txt {
  font-family: var(--font-roboto-condensed), var(--font-open-sans), sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.36px;
  text-transform: uppercase;
}

/* paragraph-18-reg */
%paragraph_18_reg {
  font-family: var(--font-open-sans), sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.36px;
}

/* paragraph-18-med */
%paragraph_18_med {
  font-family: var(--font-open-sans), sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.36px;
}

/* paragraph-16-reg */
%paragraph_16_reg {
  font-family: var(--font-open-sans), sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}

/* paragraph-16-med */
%paragraph_16_med {
  font-family: var(--font-open-sans), sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}

/* paragraph-14-reg */
%paragraph_14_reg {
  font-family: var(--font-open-sans), sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
}

/* paragraph-14-med */
%paragraph_14_med {
  font-family: var(--font-open-sans), sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
}

%section_title_bottom_line {
  position: relative;

  font-family: var(--font-open-sans-condensed);
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 60px */
  letter-spacing: -1.5px;
  text-transform: uppercase;

  @include sm {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 48px */
    letter-spacing: -1.2px;
    text-transform: uppercase;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
    width: 52px;
    height: 5px;
    background-color: var(--ui-main-black-lite);
  }
}

%paragraph_regular {
  /* UI/Paragraph Regular */
  font-family: var(--font-open-sans), sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
}

%paragraph_medium {
  /* UI/Paragraph Medium */
  font-family: var(--font-open-sans), sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.28px;
}

%paragraph_mini_medium {
  font-family: var(--font-open-sans), sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.24px;
}

%paragraph_mini_regular {
  font-family: var(--font-open-sans), sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.24px;
}

%header_4 {
  /* UI/Header-4 */
  font-family: var(--font-open-sans), sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.48px;
}

%header_1 {
  /* UI/Header-1 */
  font-family: var(--font);
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 30px */
}

%header_2 {
  /* UI/Header-1 */
  font-family: var(--font);
  font-size: 22px;
  font-style: normal;
  font-weight: 550;
  line-height: 30px;
}

%header_3 {
  font-family: var(--font);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 23.4px */
}
