@import "shared/styles/typography.scss";

.offers_bar {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ui-main-black);
  padding: 11px var(--container-horizontal-padding);
  position: relative;
  z-index: 30;

  svg {
    width: 17px;
    height: 17px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    color: var(--ui-main-white);
    @extend %paragraph_mini_medium;

    &:hover {
      color: var(--ui-main-white);
    }
  }
}

.page_header {
  background: var(--header-bg);
  border-bottom: var(--header-border-width) solid var(--header-border);
  padding: 10px;
  --color: var(--header-color);
  position: relative;
  z-index: 30;

  @media (max-width: 600px) {
    padding: 0;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.container {
  max-width: var(--container-max-width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.left {
  display: flex;
  flex-shrink: 0;
}

.center {
  min-width: 0;
  height: 100%;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 50px;
  flex: 1;
  padding: 0 12px;
  min-width: 0;

  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 22px;

    @media (max-width: 600px) {
      height: 18px;
    }
  }
}

.right {
  flex-shrink: 0;
  display: flex;
}
