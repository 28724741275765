.one_time_code_input {
  width: 100%;
  position: relative;
  overflow: hidden;

  --otp-digits: 4;
  --otp-ls: 2ch;
  --otp-gap: 1.25;

  /* private consts */
  --_otp-bgsz: calc(var(--otp-ls) + 1ch);
  --_otp-digit: 0;

  input {
    all: unset;

    position: relative;
    min-width: 400px;
    width: 400px;

    background: linear-gradient(90deg, var(--otp-bg, #ddd) calc(var(--otp-gap) * var(--otp-ls)), transparent 0),
      linear-gradient(90deg, var(--otp-bg, #eee) calc(var(--otp-gap) * var(--otp-ls)), transparent 0);
    background-position:
      calc(var(--_otp-digit) * var(--_otp-bgsz)) 0,
      0 0;
    background-repeat: no-repeat, repeat-x;
    background-size: var(--_otp-bgsz) 100%;
    caret-color: var(--otp-cc, #222);
    caret-shape: block;
    clip-path: inset(0% calc(var(--otp-ls) / 2) 0% 0%);
    font-family: ui-monospace, monospace;
    font-size: var(--otp-fz, 2.5em);
    inline-size: calc(var(--otp-digits) * var(--_otp-bgsz));
    letter-spacing: var(--otp-ls);
    padding-block: var(--otp-pb, 1ch);
    padding-inline-start: calc(((var(--otp-ls) * var(--otp-gap) - 1ch) / 2));
  }

  &.error {
    input {
      color: red;
    }
  }
}

.loader_block {
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc(100% - 285px);
  right: 0;
  background: white;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  z-index: 1;
  width: 24px;
  height: 24px;
  border: 1.5px solid var(--text-color);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
