@import "shared/styles/media";
@import "shared/styles/typography.scss";

.unicorn_box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 13px 17px;
  justify-content: center;
  align-items: center;
  color: var(--white);

  border-radius: 10px;
  background: #1e1f1f;

  @include md {
    flex-shrink: 0;
  }

  &:hover {
    color: var(--white);
    background: #333;
  }

  .logo {
    width: 35px;
    height: 35px;
  }

  .text {
    display: block;

    @extend %paragraph_16_med;

    span {
      display: block;
      color: var(--medium-gray);

      @extend %paragraph_14_reg;
    }
  }
}
