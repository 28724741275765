@import "shared/styles/typography.scss";

.input {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  padding: 12px 13px;
  gap: 5px;

  border: 1px solid var(--input-border-color);
  border-radius: 8px;
  background: var(--ui-main-white);
  color: var(--text-color);
  cursor: text;

  height: 48px;

  min-width: 0;
  appearance: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  font-family: var(--font-open-sans), sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.28px;

  &::placeholder {
    color: var(--ui-gray-gray-30);
  }

  &:hover {
    border-color: var(--medium-gray);
  }

  &:focus {
    border-color: var(--dark-gray);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &.small {
    height: 50px;
  }

  &.medium {
    height: 60px;
  }

  &.large {
    height: 70px;
  }
}
