@import "shared/styles/typography.scss";
@import "shared/styles/media";

.button {
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  transition: all 0.3s linear;
  justify-content: center;
  align-items: center;
  font-family: var(--font) !important;
  position: relative;
  user-select: none;

  &.full_width {
    width: 100%;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  &:active:not(:disabled) {
    transition: all 0.3s linear;
    transform: scale(0.95);
  }

  &.large {
    padding: 15px 20px;
    @extend %paragraph_medium;
  }

  &.medium {
    padding: 8px 10px;
    @extend %paragraph_medium;
  }

  &.white {
    color: var(--black);
    border-color: var(--stroke-color);
    background-color: var(--white);
    &:hover {
      border-color: var(--ui-main-black);
    }
  }

  &.black {
    border-color: var(--black);
    background-color: var(--black);
    color: var(--white);

    &:hover {
      background-color: var(--dark-dark-gray);
      border-color: var(--dark-dark-gray);
    }
  }

  &.accent {
    border-color: var(--accent-dark);
    background-color: var(--accent-dark);
    color: var(--white);

    &:hover {
      border-color: var(--accent);
      background-color: var(--accent);
    }
  }

  &.white_accent {
    border-color: var(--stroke-color);
    background-color: var(--white);
    color: var(--accent-dark);

    &:hover {
      color: var(--accent);
    }
  }

  &.pink {
    background-color: var(--accent);
    border-color: var(--accent);
    color: var(--white);
    border-radius: 8px;
    font-weight: 500;
    font-family: var(--font);
    font-size: 16px;

    &:hover {
      background-color: var(--accent);
      border-color: var(--accent);
    }
  }

  &:disabled {
    background: var(--medium-gray);
    border-color: var(--medium-gray);
    cursor: default;
    color: var(--stroke-color);

    &:hover {
      background: var(--medium-gray);
      border-color: var(--medium-gray);
    }
  }
}

.loader {
  position: absolute;
  right: 16px;

  width: 16px;
  height: 16px;
  border: 1px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
