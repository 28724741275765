@import "shared/styles/typography.scss";

.input {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  padding: 12px 13px 10px;
  gap: 5px;
  position: relative;
  overflow: hidden;

  border: 1px solid var(--input-border-color);
  border-radius: 8px;
  background: var(--ui-main-white);
  cursor: text;

  height: 48px;

  &:hover {
    border-color: var(--medium-gray);
  }

  color: var(--ui-gray-gray-50);

  input {
    width: 100%;
    min-width: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    font-family: var(--font-open-sans), sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.28px;

    &::placeholder {
      color: var(--ui-gray-gray-30);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .placeholder {
    position: absolute;
    top: 50%;
    left: 13px;
    transform: translateY(-50%);
    pointer-events: none;
    color: var(--ui-gray-gray-30);
    transition: all 0.3s ease;

    font-family: var(--font-open-sans), sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.28px;

    &.focused {
      transition: all 0.3s ease;
      transform: translateY(calc(-150% + 2px));
      font-size: 12px;
    }
  }

  &.small {
    height: 50px;
  }

  &.medium {
    height: 60px;
  }

  &.large {
    height: 70px;
  }

  &.accent {
    border-width: 2px;
  }

  &.focus {
    border-color: var(--medium-gray);
  }

  &.no_outline {
    border-color: transparent !important;
    outline: none !important;
  }
}
