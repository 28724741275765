@import "shared/styles/media.scss";
@import "shared/styles/typography.scss";

.modal_wrapper {
  position: fixed;
  inset: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  padding: 40px 0;
  color: var(--text-color);
  overflow: hidden;

  @include sm {
    padding: 0;
  }

  .overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 0;
  }

  .modal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .close_button {
    width: 30px;
    height: 30px;
    background: transparent !important;
    border: none;
    cursor: pointer;
    padding: 0 !important;
    color: var(--white) !important;
    z-index: 999;
    margin-top: 30px;

    svg {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
    }

    @include sm {
      top: 15px;
      left: calc(100% - 45px);
      z-index: 9999;
      color: var(--black) !important;
    }
  }

  .content {
    display: flex;
    max-width: 454px;
    width: 100%;
    padding: 40px;
    border-radius: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    background: var(--white);
    position: relative;
    z-index: 9;
    max-height: calc((var(--vh, 1vh) * 100) - 80px);
    overflow: auto;
    margin-left: 40px;
    margin-right: 10px;

    @include md {
      margin-left: 0;
      margin-right: 0;
      max-height: calc((var(--vh, 1vh) * 100) - 0);
    }

    @include sm {
      height: calc(var(--vh, 1vh) * 100);
      padding: var(--container-horizontal-padding);
      max-height: unset;
      max-width: max-content;
      border-radius: 0;
      padding-top: calc(var(--container-horizontal-padding) + 45px);
    }

    .heading {
      display: flex;
      align-items: flex-start;
      gap: 25px;
      align-self: stretch;
      justify-content: space-between;
    }

    .title {
      @extend %header_1;
    }
  }
}
