.checkout_header {
  display: flex;
  gap: 10px;
  font-family: var(--font);
  color: var(--text-color);

  //border-bottom: 1px solid var(--stroke-color);
}

.text {
  color: var(--Black, #000);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
