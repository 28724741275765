@import "shared/styles/typography.scss";
@import "shared/styles/media.scss";

.pagination {
  display: flex;
  align-items: center;
  gap: 5px;

  @include md {
    display: none;
  }

  .button {
    display: flex;
    align-items: center;
    gap: 5px;

    padding: 13px 23px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-family: var(--font);
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    padding: 13px 23px;
    height: 48px;

    svg {
      width: 22px;
      height: 22px;
    }

    &.active,
    &:hover {
      color: var(--black);
      border-color: #222;
    }

    &.next_button,
    &.prev_button {
      background: var(--ui-main-light-gray);
      padding: 8px;
    }
  }
}

.show_more_button {
  display: flex;
  width: 100%;
  background: var(--background);
  border-color: var(--background);
  padding: 15px 23px !important;
  font-family: var(--font);
  font-size: 14px;
  text-align: center;
  font-weight: 600;

  &:disabled {
    background: var(--background);
    border-color: var(--ui-gray-gray-20);
    opacity: 0.5;
    color: var(--ui-main-black);

    &:hover {
      background: var(--background);
      border-color: var(--ui-main-black);
    }
  }
}
