.checkout_page {
  max-width: 600px;
  margin: 0 auto;
  padding: 16px;
  padding-bottom: 20px;
  overflow: hidden;

  font-family:
    SF Pro Display,
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    sans-serif;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  hr {
    width: 100%;
    height: 0;
    border: none;
    border-top: 1px solid var(--Gray, #cecece);
  }
}

.loader_block {
  position: absolute;
  inset: 0;
  background: white;

  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100vh;

  .loader {
    width: 16px;
    height: 16px;
    border: 1px solid black;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.returnableBox {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    min-width: 24px;
    min-height: 24px;
  }

  .text {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    color: #92929f;
    span {
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
}
