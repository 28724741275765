@import "shared/styles/media";

.favorite_button {
  -webkit-tap-highlight-color: transparent;

  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  box-shadow: none;
  border: none;
  background: none;

  cursor: pointer;

  svg {
    color: var(--color, var(--medium-gray));
    fill: white;
    transition: all 0.2s ease-in;
  }

  @media (min-width: #{$sm + 1}px) {
    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }

  &.on {
    svg {
      color: var(--primary);
      fill: var(--primary);
      transition:
        fill 0.2s ease-in,
        transform 0.2s ease-in;
    }
  }
}
