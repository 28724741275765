@import "shared/styles/media";

.wrap {
  width: 100%;
  padding-bottom: 64%;
  position: relative;
}

.scroll {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    //pointer-events: none;
    //user-select: none;
    height: 100%;
    min-width: 100%;
    scroll-snap-stop: always;
    scroll-snap-align: start;
    position: relative;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      color: transparent;
      object-fit: contain;
    }
  }
}

.dots {
  position: absolute;
  right: 4px;
  bottom: -4px;

  width: 24px;
  height: 6px;
  overflow: hidden;
  pointer-events: none;
  user-select: none;

  @media (min-width: #{$sm + 1}px) {
    display: none;
  }

  & > div {
    display: flex;
    align-items: center;
    transition: transform 0.3s;
    gap: 4px;
    margin-left: auto;

    & > div {
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: var(--medium-gray);
      flex-shrink: 0;

      transition: all 0.2s;

      border: 1px solid white;

      &.active {
        width: 4px;
        height: 4px;
        background: black;
        border-color: transparent;
      }
    }
  }
}
