.button {
  display: flex;
  height: 60px;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: relative;

  border-radius: 12px;
  background: #ff385c;

  border: none;
  outline: none;

  box-shadow: 0px 10px 20px 0px rgba(255, 195, 124, 0.6) inset;

  color: var(--White, #fff);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  transition: transform 0.2s ease-in;

  -webkit-tap-highlight-color: transparent;

  &:focus,
  &:active,
  &:visited,
  &:hover {
    color: var(--White, #fff);
  }

  &:active {
    transform: scale(0.95);
  }
}

//.loader {
//  position: absolute;
//  right: 16px;
//}

.loader {
  position: absolute;
  right: 16px;

  width: 24px;
  height: 24px;
  border: 2px solid currentColor;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
