@import "shared/styles/media.scss";
@import "shared/styles/typography.scss";

.heading {
  padding: 15px var(--container-horizontal-padding) 40px;
  border-bottom: 1px solid var(--stroke-color);

  .search_row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 40px;

    .search {
      width: 508px;
      height: auto;

      @include lg {
        display: none !important;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      margin-top: 20px;
      @extend %header_1;
    }

    @include lg {
      gap: 20px;
      .search_row {
        flex-direction: column;
        gap: 20px;

        .search {
          width: 100%;
        }
      }
    }
  }
}
