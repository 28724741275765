@import "shared/styles/typography.scss";
@import "shared/styles/media.scss";

.hero_block {
  width: 100%;
  min-height: 75vh;
  height: 100%;
  padding: 64px var(--container-horizontal-padding);
  position: relative;
  color: var(--white);
  display: flex;
  align-items: center;

  @include sm {
    padding: 40px var(--container-horizontal-padding);
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .background_image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    z-index: 0;
  }

  .container {
    max-width: 730px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    z-index: 2;
    margin: 0 auto;

    .breadcrumbs {
      flex-wrap: wrap;
      .breadcrumb {
        color: #fff;
      }
    }

    .title {
      @extend %section_title_bottom_line;
      &::after {
        left: 0;
        transform: translate(0);
      }

      @include sm {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 48px */
        letter-spacing: -1.2px;
        text-transform: uppercase;
      }
    }

    .keywords {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;

      .keyword {
        @extend %paragraph_14_med;
        cursor: default;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      .description {
        @extend %paragraph_18_reg;
      }

      .button {
        display: inline;
      }
    }
  }
}
