@import "shared/styles/typography.scss";

.pill {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  transition: all 0.3s linear;
  padding: 10px 15px;
  border-radius: 99999px;
  @extend %paragraph_14_med;

  &:active {
    transition: all 0.3s linear;
    transform: scale(0.95);
  }

  &.white {
    color: var(--black);
    border-color: var(--white);
    background-color: var(--white);
    &:hover {
      border-color: var(--medium-gray);
    }
  }

  &.dark {
    color: var(--white);
    border-color: #292929;
    background-color: #292929;
    &:hover {
      border-color: var(--stroke-color);
    }
  }

  &.gray {
    color: var(--black);
    border-color: var(--background);
    background-color: var(--background);
    &:hover {
      border-color: var(--medium-gray);
    }
  }
}
