@import "shared/styles/media";
@import "shared/styles/typography.scss";

.page_footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--black);

  .main_section {
    width: 100%;
    background: var(--black);
    --color: var(--white);
    color: var(--color);
    padding: 41px 20px;

    .container {
      display: flex;
      flex-direction: column;
      max-width: var(--container-width);
      width: 100%;
      gap: 60px;
      margin: 0 auto;
      @include md {
        gap: 50px;
      }
    }

    .link {
      &:hover {
        --color: var(--accent-dark);
      }
    }

    .first_section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 20px;

      @include md {
        align-items: flex-start;
      }

      @include sm {
        gap: 30px;
        flex-wrap: wrap;
      }

      .left_side {
        display: flex;
        flex-direction: row;
        gap: 35px;
        align-items: center;

        @extend %paragraph_14_med;

        @include md {
          flex-wrap: wrap;
        }
      }

      .logo {
        width: 205px;
        height: auto;
      }

      .address_box {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 6px;
        .icon {
          width: 20px;
          height: 20px;
        }

        .text {
          display: flex;
          flex-direction: column;
          color: var(--medium-gray);
          a {
            color: var(--white);
            text-decoration: underline;
            &:hover {
              color: var(--accent-dark);
            }
          }
        }
      }
    }

    .links {
      display: flex;
      flex-direction: row;
      gap: 30px;
      width: 100%;
      height: auto;
      align-items: stretch;

      @include md {
        flex-wrap: wrap;
        gap: 30px 20px;
      }

      .links_box {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        padding-right: 30px;

        border-right: 1px solid var(--dark-gray, #3e3e3e);

        @include md {
          width: calc(50% - 10px);
          border: none;
        }

        .category_name {
          text-transform: uppercase;

          @extend %header_4_txt;
        }

        .link_list {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .link {
            @extend %paragraph_14_med;
          }

          a {
            color: var(--medium-gray);

            &:hover {
              color: var(--accent-dark);
            }
          }
        }
      }
    }
  }

  .info_section {
    color: var(--medium-gray);
    background: var(--dark-dark-gray);
    width: 100%;
    padding: 38px 20px;

    @include sm {
      padding: 30px 15px;
    }

    .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      max-width: var(--container-width);
      margin: 0 auto;
      width: 100%;
      gap: 30px;

      .info_title {
        @extend %paragraph_14_med;
      }

      @include md {
        flex-wrap: wrap;
      }

      .right_side {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 46px;

        @include md {
          flex-wrap: wrap;
        }

        .links {
          display: flex;
          flex-direction: row;
          gap: 40px;
          align-items: center;

          @include sm {
            flex-wrap: wrap;
            gap: 20px 10px;
          }

          .link {
            position: relative;
            color: var(--medium-gray);

            @extend %paragraph_14_med;

            &::after {
              content: "";
              width: 1px;
              height: 100%;
              position: absolute;
              right: -20px;
              top: 0;
              background: var(--dark-gray);
              display: block;
              pointer-events: none;
            }

            &:last-child {
              &::after {
                display: none;
              }
            }

            @include sm {
              &::after {
                display: none;
              }
            }

            &:hover {
              color: var(--accent);
            }
          }
        }

        .social_links {
          display: flex;
          flex-direction: row;
          gap: 20px;
          align-items: center;

          .logo {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
