@import "shared/styles/media.scss";
@import "shared/styles/typography.scss";

.container {
  position: relative;
  z-index: 999999999;
}

.overlay {
  position: fixed;
  inset: -1000px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;

  &.close {
    animation: hide 0.3s ease-in-out forwards;
  }

  &.close_desktop {
    animation: hide 0.3s ease-in-out forwards;
  }

  animation: appear 0.3s ease-in-out forwards;

  backdrop-filter: blur(2px);

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 9999999;
  padding: 40px 0;
  color: var(--text-color);
  pointer-events: none;

  @include sm {
    padding: 0;
    padding-top: 20px;

    bottom: -100vh;
    padding-bottom: 100vh;
  }

  .close_button {
    pointer-events: auto;
    position: absolute;
    left: calc(50% + ((454px / 2) + 10px));
    width: 30px;
    height: 30px;
    background: transparent !important;
    border: none;
    cursor: pointer;
    padding: 0 !important;
    color: var(--white) !important;
    z-index: 999;

    svg {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
    }

    @include sm {
      top: 15px;
      left: calc(100% - 45px);
      z-index: 9999;
      color: var(--black) !important;
    }
  }

  .content {
    pointer-events: auto;
    display: flex;
    max-width: 454px;
    width: 100vh;
    padding: 40px;
    border-radius: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    background: var(--white);
    position: relative;
    z-index: 9;
    max-height: 100%;

    &.no_padding {
      padding: 0 !important;
    }

    &.close_desktop {
      animation: hide 0.25s ease-in-out forwards;
    }

    @include sm {
      position: relative;
      height: fit-content;
      width: 100%;
      max-width: unset;

      padding: 30px var(--container-horizontal-padding);
      padding-bottom: 15px;

      border-radius: 0;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      animation: appear_content 0.3s ease-in-out;

      &.full_height {
        height: 100%;
        //height: calc(100vh - 80px);
        //max-height: calc(100vh - 80px);
      }

      &.close {
        animation: hide_content 0.3s ease-in-out forwards;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: -100vh;
        left: 0;
        right: 0;
        height: 100vh;
        background: white;
      }

      @keyframes appear_content {
        0% {
          transform: translateY(100%);
        }
        100% {
          transform: translateY(0);
        }
      }

      @keyframes hide_content {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(100%);
        }
      }
    }

    .heading {
      display: flex;
      align-items: flex-start;
      gap: 25px;
      align-self: stretch;
      justify-content: space-between;
    }

    .title {
      @extend %header_1;
    }
  }
}
