@import "shared/styles/typography.scss";

.input {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  padding: 12px 13px;
  gap: 5px;

  border: 1px solid var(--input-border-color);
  border-radius: 8px;
  background: var(--ui-main-white);
  cursor: text;

  height: 48px;

  &:hover {
    border-color: var(--medium-gray);
  }

  @extend %paragraph_medium;
  color: var(--ui-gray-gray-50);

  input {
    width: 100%;
    min-width: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    font-size: inherit;
    text-align: inherit;

    @extend %paragraph_medium;
    font-size: 16px;

    &::placeholder {
      color: var(--ui-gray-gray-30);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &.with_prefix {
  }

  &.no_prefix_styling {
  }

  &.with_suffix {
  }

  &.no_suffix_styling {
  }

  &.small {
    height: 50px;
  }

  &.medium {
    height: 60px;
  }

  &.large {
    height: 70px;
  }

  .prefix,
  .suffix {
    display: flex;
    align-items: center;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .prefix {
  }

  &.accent {
    border-width: 2px;
  }

  &.focus {
    border-color: var(--medium-gray);
  }

  &.no_outline {
    border-color: transparent !important;
    outline: none !important;
  }

  &.focus.no_prefix_styling .prefix {
  }

  &.readonly .prefix {
  }

  .suffix {
  }

  &.focus.no_suffix_styling .suffix {
  }

  &.readonly .suffix {
  }
}
