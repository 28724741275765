@import "shared/styles/media";
@import "shared/styles/typography.scss";

.product_card {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  color: #000;
  background: #fff;

  transition: all 0.3s ease;

  //@media (min-width: #{$sm + 1}px) {
  //  padding: 4px;
  //
  //  &:hover {
  //    transition: all 0.3s ease;
  //    border-color: var(--black);
  //
  //    border-radius: 10px;
  //    background: var(--white, #fff);
  //
  //    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.09);
  //  }
  //}
}

.card_body {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 0;

  font-family: var(--font);

  .name {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    //word-break: break-all;
    //white-space: break-spaces;
  }

  .properties {
    display: flex;
    align-items: center;
    gap: 5px;

    .propertiesAvailable {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
    }

    .last {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
      color: #ff395c;
    }

    .delivery {
      font-size: 11px;
      color: #656565;
      letter-spacing: -0.01em;

      .icon {
        vertical-align: bottom;
      }

      .now {
        color: darkgreen;
      }
    }
    .rating {
      font-size: 10px;
      font-weight: 700;
      line-height: 140%;
      color: #ffa336;
    }
  }
}

.favorite {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.size {
  font-size: 10px;
}
