@import "shared/styles/typography.scss";

.menu {
  display: flex;
  position: relative;
  .menu_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    @extend %paragraph_16_med;

    .chevron_down {
      color: var(--medium-gray);
      width: 22px;
      height: 22px;
    }

    &:hover {
      color: var(--accent);
    }
  }

  .menu_wrapper {
    width: 0;
    display: flex;
  }

  .menu_list {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    gap: 10px;
    padding: 11px 20px;
    min-width: 190px;
    margin: 0;
    list-style-type: none;
    flex-shrink: 0;

    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
    background: var(--white);
    border-radius: 5px;
    padding: 11px 20px;

    &::after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: var(--white);
      transform: rotate(45deg) translate(-50%, 0);
      top: -2px;
      left: 50%;
    }

    &.hide_menu_chevron {
      top: calc(100% + 0px);
      &::after {
        display: none;
      }
    }

    &.left {
      left: -20px;
      transform: translateX(0);
      &::after {
        left: 25px;
        transform: rotate(45deg) translate(-50%, 0);
      }
    }

    &.right {
      left: inherit;
      right: -20px;
      transform: translateX(0);
    }

    .menu_item {
      padding: 0;
      margin: 0;
      display: flex;
      flex-shrink: 0;

      @extend %paragraph_14_med;

      --color: var(--text-color);
      a {
        width: 100%;
      }
    }
  }
}
