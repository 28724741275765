@import "shared/styles/typography.scss";

.point_card {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  justify-content: space-between;
  width: 100%;

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    background-color: #f0eff2;
    font-size: 22px;
    text-align: center;
    width: 49px;
    height: 49px;
    flex: 0 0 49px;
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: space-between;
    width: calc(100% - 59px);
    padding-bottom: 13px;
    border-bottom: 1px solid #e0e0e0;
    .name_points_line {
      display: flex;
      gap: 8px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      font-family: var(--font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .points {
        &.plus {
          color: #18aa42;
        }
      }
    }
    .desc_date_line {
      display: flex;
      gap: 8px;
      flex-direction: row;
      justify-content: space-between;

      font-family: var(--font);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 16.8px */

      .desc {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #8b8b8b;

        a {
          color: #0085ff;
        }

        &.primary {
          color: #0085ff;
        }
      }

      .date {
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: #8b8b8b;
        flex-shrink: 0;
      }
    }
  }

  &:last-child {
    .details {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}
