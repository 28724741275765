.product_card_price {
  font-family: var(--font);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;

  &.sale {
    color: var(--red);
  }

  span {
    font-size: 0.75em;
    line-height: 0;
  }

  &.num {
    font-size: 18px;
    line-height: 16px;
  }
}

.price_without_discount {
  color: #656565;
  font-weight: 400;

  white-space: nowrap;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 49%;
    height: 1px;
    background: rgba(0, 0, 0, 0.35);
    left: -1px;
    right: -1px;
    z-index: 1;
    pointer-events: none;
  }
}
