@import "shared/styles/typography.scss";

.accordion {
  display: flex;
  flex-direction: column;
  padding: 14px 0;
  border-top: 1px solid var(--stroke-color);
  gap: 10px;
  .heading {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;

    .title {
      @extend %header_3_txt;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .chevron_icon {
      width: 31px;
      height: 31px;
      flex-shrink: 0;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s linear;

    &.open {
      height: auto;
      overflow: visible;
    }
    p {
      margin-bottom: 10px;

      @extend %paragraph_18_reg;
      a {
        color: var(--accent-dark);
        @extend %paragraph_18_med;
      }
    }

    strong,
    b {
      @extend %paragraph_18_med;
    }

    ul,
    ol {
      margin-top: 10px;
      margin-bottom: 20px;
      padding-left: 25px;
    }
  }
}
