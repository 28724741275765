.product_card_split {
  font-family:
    "SF Pro Display",
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    sans-serif;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -0.03em;
  margin-top: -4px;
}

.price {
  background: var(--dark-gray);
  color: white;
  padding: 0 3px;
  border-radius: 4px;
  font-weight: 500;

  span {
    font-size: 0.8em;
  }
}
