@import "shared/styles/media.scss";
@import "shared/styles/typography.scss";

.chinese_holiday_banner {
  display: flex;
  padding: 15px;
  align-items: stretch;
  gap: 15px;

  border-radius: 15px;
  background: linear-gradient(
      104deg,
      rgba(255, 56, 92, 0.2) 2.54%,
      rgba(255, 56, 92, 0) 16.47%,
      rgba(255, 56, 92, 0) 80.01%,
      rgba(255, 56, 92, 0.2) 98.51%
    ),
    linear-gradient(0deg, #fff4f6 0%, #fff4f6 100%), #f2f2f2;

  .hr {
    width: 1px;
    height: 100%;
    flex-shrink: 0;
    border-left: 1px solid #c5c5c5;
    border-bottom: none;
    border-top: none;
    border-right: none;
    background: none;
  }

  @include md {
    flex-direction: column;
    padding: 15px 15px 15px 15px;
    gap: 10px;

    .hr {
      width: 100%;
      height: 1px;
      border-left: none;
      border-bottom: 1px solid #c5c5c5;
      border-top: none;
      border-right: none;
      background: none;
    }
  }

  &.col {
    flex-direction: column;
    padding: 15px 15px 15px 15px;
    gap: 10px;

    .hr {
      width: 100%;
      height: 1px;
      border-left: none;
      border-bottom: 1px solid #c5c5c5;
      border-top: none;
      border-right: none;
      background: none;
    }
  }

  .col {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    .title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    }

    .sub_title {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */

      b {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
    }

    .link {
      width: 100%;
    }

    .button {
      display: flex;
      width: 100%;
      border-radius: 10px;
      background: #fff;
      padding: 10px 10px 13px 10px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-color: #fff;
      gap: 0;

      .gradient_text {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        background: radial-gradient(
          104.03% 101.34% at 7.32% 59.63%,
          #eb9c00 0%,
          #ff4769 27.08%,
          #3d50ff 66.38%,
          #00b2ff 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .desc {
        display: flex;
        align-items: center;
        .icon {
          width: 14px;
          height: 14px;
        }
        display: flex;
        flex-direction: row;

        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        color: #8b8b8b;
      }
    }
  }
}
