@import "shared/styles/typography.scss";

.navigation_menu {
  align-items: center;
  display: none;

  @include lg {
    display: flex;
  }

  .navigation_menu__button {
    color: var(--white);
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;

    display: none;

    @include lg {
      display: block;
    }
  }

  .menu_box {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 80px;
    width: 100vw;
    z-index: 99999;
    max-height: calc(100% - 80px);
    height: auto;
    overflow: auto;
    background: var(--white);
    flex-direction: column;
    padding: 15px 0 0;

    &::-webkit-scrollbar {
      display: none;
    }

    &.open {
      display: flex;
    }

    .heading {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      padding: 0 15px;

      .input {
      }

      .catalog_list {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;

        overflow: auto;
        flex-wrap: nowrap;
        width: calc(100% + 30px);
        margin: 0 -15px;
        padding: 0 15px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .info_first_items {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      .info_first_item {
        display: flex;
        padding: 19px 15px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-top: 1px solid var(--stroke-color);

        @extend %paragraph_16_med;

        &:last-child {
          border-bottom: 1px solid var(--stroke-color);
        }
      }
    }

    .info_second_items {
      background: var(--background);
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .info_second_item {
        border: 1px solid var(--stroke-color);
        background: var(--white);
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 19px 15px;
        @extend %paragraph_16_med;
        gap: 5px;

        .logo {
          width: 22px;
          height: 22px;
          flex-shrink: 0;
          object-fit: contain;
        }
      }
    }
  }
}
