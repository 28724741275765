@import "shared/styles/typography.scss";
@import "shared/styles/media.scss";

.blog_page {
  width: 100%;

  .wrapper {
    padding: 30px var(--container-horizontal-padding) 80px;
  }

  .container {
    max-width: 730px;
    margin: 0 auto;
    display: flex;

    flex-direction: column;
    gap: 30px;

    @include sm {
      gap: 20px;
    }

    .heading {
      .title {
        @extend %section_title_bottom_line;
        text-align: left;
        &::after {
          left: 0;
          transform: translate(0);
        }
      }
    }

    .content {
      img {
        width: 100%;
        height: auto;
      }

      h1 {
        @extend %header_2_txt;
        margin-top: 60px;
      }

      h2 {
        @extend %header_3_txt;
        margin-top: 30px;
      }

      h3 {
        @extend %paragraph_18_med;
        margin-top: 20px;
      }

      p {
        margin-top: 10px;

        @extend %paragraph_18_reg;
        a {
          color: var(--accent-dark);
          @extend %paragraph_18_med;
        }
      }

      ul,
      ol {
        margin-top: 10px;
        padding-left: 25px;
        li {
          @extend %paragraph_18_reg;
        }
      }

      blockquote {
        margin-top: 10px;
        @extend %big_txt;
        p {
          @extend %big_txt;
        }
      }

      button {
        display: flex;
        flex-direction: row;
        gap: 5px;
        cursor: pointer;
        border-width: 1px;
        border-style: solid;
        transition: all 0.3s linear;
        justify-content: center;
        padding: 21px 32px;
        @extend %header_4_txt;
        text-transform: uppercase;
        svg {
          width: 22px;
          height: 22px;
        }

        &:active {
          transition: all 0.3s linear;
          transform: scale(0.95);
        }
      }
    }
  }
}
