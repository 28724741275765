.need_help {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item {
  display: flex;
  gap: 10px;

  color: var(--Text-black, #222);
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;

  .icon {
    //flex-shrink: 0;

    svg {
      vertical-align: -0.15em;
    }
  }

  .text {
    //flex: 1;
    display: flex;
    align-items: center;
    gap: 6px;
  }
}
