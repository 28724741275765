@import "shared/styles/media.scss";

.product_grid_pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin: 0 -8px;

  @include lg {
    grid-template-columns: repeat(3, 1fr);
  }

  @include md {
    grid-template-columns: repeat(2, 1fr);
  }

  .product {
    position: relative;

    &:hover {
      z-index: 2;
    }
  }
}

.pagination {
  display: flex;
  align-items: flex-start;

  .button {
    border: 2px solid transparent;

    &.active,
    &:hover {
      border-color: var(--accent-dark);
    }
  }
}

.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-4x) 0;
}
