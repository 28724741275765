.controls {
  display: flex;
  gap: 15px;
  flex-direction: column;
  padding: 0;
  color: #000;
  width: 40%;
  height: 100%;
  flex-shrink: 0;
  background: #fff;
  padding: 30px;
  overflow: hidden;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

  .header {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .button {
        width: 100%;

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #000000;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
        gap: 5px;

        border: 1px solid #c5c5c5;
        border-radius: 12px;

        &.active {
          border: 2px solid #000000;
        }
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    height: calc(100% - 115px);
  }

  .selected_point {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;

    &_header {
      display: flex;
      flex-direction: row;
      gap: 30px;
      color: #000000;
      align-items: flex-start;

      .back_button {
        border: none;
        padding: 0;
        height: auto;
      }

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
      }

      svg {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: auto;

      .detail {
        display: flex;
        gap: 15px;
        color: #000;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #000000;

        svg {
          width: 22px;
          height: 22px;
          flex-shrink: 0;
        }
      }
    }
  }
}
