@import "shared/styles/media.scss";

.side_menu {
  position: fixed;
  inset: 0;
  top: calc(70px + var(--notification-height, 0px));

  z-index: 999999999999;
  background: var(--ui-main-white);
  color: var(--ui-main-black);

  overflow-y: scroll;

  padding: 15px var(--container-horizontal-padding) 40px var(--container-horizontal-padding);

  @media (max-width: 600px) {
    top: calc(50px + var(--notification-height, 0px));
    padding-top: 0;
  }

  .container {
    max-width: var(--container-max-width);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px 120px;

    @include sm {
      flex-direction: column;
      gap: 20px;
    }
  }
}
