@import "shared/styles/media.scss";

.charity_banner {
  width: 100%;
  height: auto;
  padding: 25px 30px;
  border-radius: 0px;
  background-image: url("./image/banner-background.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1030px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;

  @include md {
    background-image: url("./image/banner-background-mobile.png");
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    color: #fff;
    position: relative;
    z-index: 1;

    .title {
      font-family: var(--font-montserrat);
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 110%; /* 44px */
      letter-spacing: -1.2px;
      max-width: 715px;

      @include md {
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 110%; /* 27.5px */
        letter-spacing: -0.75px;
      }

      @include sm {
        max-width: 225px;
      }
    }

    .desc {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 28px */
      letter-spacing: -0.4px;
      max-width: 662px;

      @include lg {
        max-width: 500px;
      }

      @include md {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 18.2px */
        letter-spacing: -0.28px;
        max-width: 500px;
      }

      @include sm {
        max-width: 240px;
      }
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      align-self: stretch;
      width: auto;

      padding: 10px 15px;
      margin-top: 18px;
      flex-direction: column;

      border-radius: 0px;
      background: #fff;

      color: #0085ff;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 28px */

      @include md {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
      }
    }
  }

  .hand {
    position: absolute;
    z-index: 0;
    top: 100%;
    right: -66px;
    width: 291px;
    height: auto;
    flex-shrink: 0;

    @include md {
      width: 141px;
      right: -29px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.small {
  background-image: url("./image/banner-background-mobile.png");

  .content {
    .title {
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 110%; /* 27.5px */
      letter-spacing: -0.75px;
      max-width: 285px;

      @include md {
        max-width: 500px;
      }

      @include sm {
        max-width: 225px;
      }
    }

    .desc {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 18.2px */
      letter-spacing: -0.28px;
      max-width: 240px;

      @include md {
        max-width: 300px;
      }

      @include sm {
        max-width: 240px;
      }
    }

    .button {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
    }
  }

  .hand {
    width: 205px;

    @include sm {
      width: 141px;
      right: -29px;
    }
  }
}
