.map_wrapper {
  display: flex;
  max-width: 1000px;
  width: 100%;
  height: 100%;
  position: relative;

  .map {
    width: 100%;
    height: 100%;
  }
}
