@import "shared/styles/typography.scss";

.switch {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;

  &:hover {
    .check:not(.disabled, .checked) {
      border-color: var(--black);
    }
  }
}

.disabled {
  cursor: default;
}

.box {
  width: 40px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  @extend %paragraph_16_med;
}

.check {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background: #e9e9e9;
  transition: all 0.2s linear;

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .icon {
    height: 20px;
    width: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: transparent;
    position: absolute;
    left: 0;
    transform: translateX(1px);
    transition: all 0.2s linear;
    background: var(--white);
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2))
      drop-shadow(0px 0.10000000149011612px 0.30000001192092896px rgba(0, 0, 0, 0.1));
  }

  &.checked {
    background: var(--primary);
  }

  &:not(.disabled):hover {
    border-color: var(--black);
  }

  &.checked .icon {
    transform: translateX(calc(100% - 1px));
  }

  .disabled.checked .icon {
    color: var(--medium-gray);
  }
}
