@import "shared/styles/media.scss";
@import "shared/styles/typography.scss";

.banner {
  display: flex;
  background: rgba(255, 255, 255, 1);
  width: 100%;
  margin: 30px auto 60px;
  padding: 0;

  @include lg {
    flex-direction: column;
  }

  @include md {
    background: rgba(19, 53, 54, 1);
    padding: 18px 15px;
    gap: 30px;
  }

  .content_left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    background: rgba(19, 53, 54, 1);
    padding: 30px;
    width: 455px;
    flex-shrink: 0;
    color: #fff;

    @include lg {
      flex-direction: row;
      width: 100%;
    }

    @include md {
      background: transparent;
      padding: 0;
    }

    .header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;

      .badge {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 20px;
        border: 1px;
        gap: 4px;
        border: 1px solid rgba(255, 255, 255, 1);

        font-family: var(--font-roboto-condensed);
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: -0.02em;
        text-align: left;

        svg {
          width: 24px;
          height: 24px;
          flex-shrink: 0;
        }

        @include md {
          font-size: 18px;
          font-weight: 700;
          line-height: 25px;
          letter-spacing: -0.02em;
          text-align: left;

          padding: 6px 15px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .title {
        font-family: var(--font-open-sans-condensed);
        font-size: 50px;
        font-weight: 700;
        line-height: 55px;
        letter-spacing: -0.03em;
        text-align: left;

        @include md {
          font-size: 40px;
          font-weight: 700;
          line-height: 44px;
          letter-spacing: -0.03em;
          text-align: left;
        }
      }
    }
  }

  .link_box {
    padding: 15px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(180deg, #3fb4bc 0%, #15a8a8 100%, #1559a8 100%);
    cursor: pointer;
    color: #fff;

    @include md {
      padding: 0;
      cursor: default;
    }

    .description {
      font-family: var(--font-roboto-condensed);
      font-size: 22px;
      font-weight: 500;
      line-height: 31px;
      letter-spacing: -0.02em;
      text-align: left;
    }

    .button_box {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: flex-end;
      justify-content: space-between;

      .button {
        padding: 20px 28px;
        gap: 10px;

        background: #fff;

        font-family: var(--font-roboto-condensed);

        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: -0.02em;
        text-align: left;

        color: rgba(56, 157, 159, 1);

        @include md {
          width: 100%;
          background: linear-gradient(180deg, #3eb4bb 0%, #148a8a 100%);

          color: #fff;
          border: none;

          font-size: 18px;
          font-weight: 600;
          line-height: 25px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }

    .icons {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: flex-start;
      justify-content: center;

      img {
        width: 40px;
        height: 40px;
        flex-shrink: 0;

        @include md {
          width: 30px;
          height: 30px;
        }
      }
    }

    &.box_desktop {
      @include md {
        display: none;
      }
    }

    &.box_mobile {
      display: none;
      @include md {
        display: flex;
        background: transparent;

        .header {
          display: flex;
          flex-direction: row;
          gap: 10px;
          width: 100%;

          .description {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: -0.02em;
            text-align: left;

            span {
              color: rgba(59, 212, 219, 1);
            }
          }
        }
      }
    }
  }

  .content_right {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include md {
      background: rgba(255, 255, 255, 1);
      padding: 20px 15px;
      gap: 20px;
    }

    .header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 36px;

      .title {
        font-family: var(--font-roboto-condensed);
        font-size: 40px;
        font-weight: 700;
        line-height: 44px;
        letter-spacing: -0.03em;
        text-align: left;

        @include md {
          font-size: 30px;
          font-weight: 700;
          line-height: 33px;
          letter-spacing: -0.02em;
          text-align: left;
        }
      }

      &.header_mobile {
        display: none;
      }

      @include md {
        margin-bottom: 0;
        &.header_mobile {
          display: flex;
        }
      }

      .description {
        font-family: var(--font-roboto-condensed);
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: -0.02em;
        text-align: left;

        &.description_mobile {
          display: none;
        }

        @include md {
          display: none;
          &.description_mobile {
            display: block;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.02em;
            text-align: left;
          }
        }
      }
    }

    .gifts {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: stretch;
      width: 100%;

      .item {
        width: calc((100% / 3) - (20px / 3));
        height: auto;
        object-fit: contain;

        &.item_mobile {
          display: none;
        }

        @include md {
          display: none;
          &.item_mobile {
            display: block;
          }
        }
      }
    }
  }
}
